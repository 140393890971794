import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

/**
 * 秒杀商品列表
 * @param {*} param0
 */
export const secKillList = async ({pageSize, pageNum, keyword}) => {
    return http({
        method: Method.POST,
        url: DOMAIN + '/sec/kill/list',
        data: {
            page: pageNum,
            size: pageSize,
            keyword
        }
    }).then(({data}) => {
        const list = data.msg;

        return {list};
    }).catch(err => {
        if (err === '拼多多') {
            return Promise.resolve({list: []})
        } else if (err === "网络错误") {
            return secKillList({pageSize, pageNum, keyword})
        }
    });
};
