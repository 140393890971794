import {computed, observable} from "mobx";

class SelectOrderAddressStore {
    @observable _list = [];
    @observable _id = 0;

    @computed get currentAddress() {
        if (!this._id) {
            return null;
        }

        return this._list.find(item => item.id === this._id);
    }

    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }
}

const store = new SelectOrderAddressStore()

export default store
