import {observable, action} from 'mobx';

class ConfirmGoods {
    @observable _show = false;
    @observable _list = [
        {
            id: 1,
            name: '商品名称1',
            currentPrice: 2300,
            originPrice: 3000,
            count: 10,
            img: '',
            specificationMap: new Map([
                ['重量', '3g'],
                ['颜色', '白色']
            ])
        },
        {
            id: 2,
            name: '商品名称2',
            currentPrice: 2300,
            originPrice: 3000,
            count: 10,
            img: '',
            specificationMap: new Map([
                ['重量', '4g'],
                ['颜色', '红色']
            ])
        }
    ];
    @observable _phone = '';
    @observable _name = '';
    @observable _onClick = undefined;

    get show() {
        return this._show;
    }

    set show(value) {
        this._show = value;
    }

    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }

    get phone() {
        return this._phone;
    }

    set phone(value) {
        this._phone = value;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get onClick() {
        return this._onClick;
    }

    set onClick(value) {
        this._onClick = value;
    }
}

const store = new ConfirmGoods();

export default store;
