import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

import ImgEnum from 'enum/ImgEnum';


export const floorList = () => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/api/index/floor',
    })
        .then(res => res.data)
        .then(list => list.slice(0, 3))
        .then(floorList => floorList?.map(floor => {
            let originImgList = [...floor.mobileImgList];
            let imgList = [];
            let rightDownImg = '';

            for (let j in originImgList) {
                const {type} = originImgList[j];

                if (type === ImgEnum.CATEGORY_RIGHT_IMG) {
                    rightDownImg = originImgList[j];
                } else if (type === ImgEnum.CATEGORY_IMG) {
                    imgList.push(originImgList[j]);
                }
            }

            floor.imgList = imgList;
            floor.rightDownImg = rightDownImg;

            return floor;
        }) || []);
}

export const floorInfo = ({id}) => {
    return http({
        method: Method.GET,
        url: DOMAIN + `/api/index/floor/${id}`,
        params: {
            page: 1,
            size: 15
        }
    })
        .then(res => res.data)
        .catch(err => {
            if (err === '拼多多') {
                return Promise.resolve({goodsListDTOS: []})
            } else if (err === "网络错误") {
                return floorInfo({id});
            }
        })
}