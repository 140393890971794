import {computed, observable} from "mobx";

class OrderPayStore {
    @observable _id = 0;
    @observable _sn = '';
    @observable _price = 0;
    @observable _status = undefined;
    @observable _createTime = 0;
    @observable _delivery = undefined;
    @observable _orderAddress = undefined;
    @observable _orderProductList = [];
    @observable _payType = undefined;
    @observable _payTypeList = [];
    @observable _currentPayTypeId = 0;
    @observable _showBackModel = false;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get sn() {
        return this._sn;
    }

    set sn(value) {
        this._sn = value;
    }

    get price() {
        return this._price;
    }

    set price(value) {
        this._price = value;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value;
    }

    get createTime() {
        return this._createTime;
    }

    set createTime(value) {
        this._createTime = value;
    }

    get delivery() {
        return this._delivery;
    }

    set delivery(value) {
        this._delivery = value;
    }

    get orderAddress() {
        return this._orderAddress;
    }

    set orderAddress(value) {
        this._orderAddress = value;
    }

    get orderProductList() {
        return this._orderProductList;
    }

    set orderProductList(value) {
        this._orderProductList = value;
    }

    get payType() {
        return this._payType;
    }

    set payType(value) {
        this._payType = value;
    }

    get payTypeList() {
        return this._payTypeList;
    }

    set payTypeList(value) {
        this._payTypeList = value;
    }

    get currentPayTypeId() {
        return this._currentPayTypeId;
    }

    set currentPayTypeId(value) {
        this._currentPayTypeId = value;
    }

    get showBackModel() {
        return this._showBackModel;
    }

    set showBackModel(value) {
        this._showBackModel = value;
    }
}

export default new OrderPayStore();