import React from 'react'
import './news-floor.scss'
import {inject, observer} from "mobx-react";
import {Carousel} from "antd-mobile";
import {useHistory} from 'react-router-dom'

function NewsFloor({home}) {
    const {newsList} = home;
    const history = useHistory();

    const carouselOpt = {
        vertical: true,
        dots: false,
        dragging: false,
        swiping: false,
        autoplay: true,
        infinite: true,
        speed: 200,
        autoplayInterval: 3000,
        resetAutoplay: false
    }

    function renderNewsList() {
        const toNews = (id) => () => {
            history.push({
                pathname: `/news/info/${id}`
            });
        }
        return (
            newsList?.map(({id, title}) => {
                return (
                    <div className="news-carousel-item" key={id} onClick={toNews(id)}>
                        {title}
                    </div>
                );
            })
        )
    }

    const toMore = () => {
        history.push({pathname: `/news`, search: "?categoryId=1"})
    }

    return (
        <div className="glx-home-news-floor">
            <div className="container">
                <div className="title-block">
                    <img src={require('./img/new.png')} alt="" className="new"/>
                    <img src={require('./img/title.png')} alt="" className="title"/>
                </div>
                <div className="content-block">
                    <img src={require('./img/hot.png')} alt="" className="hot"/>
                    <div className="swiper-wrapper">
                        {
                            newsList?.length &&
                            <Carousel{...carouselOpt}>
                                {renderNewsList()}
                            </Carousel>
                        }
                    </div>
                    <div className="more" onClick={toMore}>
                        <span>更多</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default inject('home')(observer(NewsFloor));