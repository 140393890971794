// 这个必须在最前面
import {isProd} from "utils/envUtil";
export { default as GetFormData } from "utils/GetFormData";

const Global = {};
Global.online = isProd;

if (Global.online) {
  Global.DOMAIN = window.BASE_URL;
  Global.LIVE_BASE_URL = window.LIVE_BASE_URL;
  Global.MINI_BASE_URL = window.MINI_BASE_URL;
} else {
  const domain = "localhost";
    // const domain = "www.ympscw.cn";

  Global.DOMAIN = `http://${domain}:8090`;
  Global.LIVE_BASE_URL = `http://${domain}:8110/live/api`;
  Global.MINI_BASE_URL = `http://${domain}:8100`;
}

export const DOMAIN = Global.DOMAIN;
export const LIVE_BASE_URL = Global.LIVE_BASE_URL;
export const MINI_BASE_URL = Global.MINI_BASE_URL;
export const ONLINE = Global.online;

export const fetchJson = (url, arg) => {
  return fetch(
    url,
    Object.assign(
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      arg
    )
  );
};

export const Header = {
  ContentType: "content-type",
};

export const ContentType = {
  FormData: "application/x-www-form-urlencoded;charset=UTF-8",
  JSON: "application/json",
};

export const Method = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export { default as http } from "./http";
