import {action, observable} from "mobx";

class OrderListStore {
    @observable _list = [];
    @observable _pageSize = 10000;
    @observable _pageNum = 1;
    @observable _showDeliveryMap = new Map();
    @observable _ifShowDeliveryModal = false;
    @observable _currentDelivery = undefined;
    @observable _currentOrder = undefined;

    @action showDelivery(orderId) {
        this._showDeliveryMap.set(orderId, true);
    }

    @action hideDelivery(orderId) {
        this._showDeliveryMap.set(orderId, false);
    }

    @action showDeliveryModal(orderId) {
        if (!this._list.length) {
            return undefined;
        }

        this._currentOrder = this._list.find(({id}) => id === orderId);

        this._currentDelivery = this._currentOrder?.delivery;

        this._ifShowDeliveryModal = true;
    }

    @action closeDeliveryModal() {
        this._ifShowDeliveryModal = false;
    }

    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }

    get pageSize() {
        return this._pageSize;
    }

    set pageSize(value) {
        this._pageSize = value;
    }

    get pageNum() {
        return this._pageNum;
    }

    set pageNum(value) {
        this._pageNum = value;
    }

    get showDeliveryMap() {
        return this._showDeliveryMap;
    }

    set showDeliveryMap(value) {
        this._showDeliveryMap = value;
    }

    get ifShowDeliveryModal() {
        return this._ifShowDeliveryModal;
    }

    set ifShowDeliveryModal(value) {
        this._ifShowDeliveryModal = value;
    }

    get currentDelivery() {
        return this._currentDelivery;
    }

    set currentDelivery(value) {
        this._currentDelivery = value;
    }

    get currentOrder() {
        return this._currentOrder;
    }

    set currentOrder(value) {
        this._currentOrder = value;
    }
}

export default new OrderListStore();