import { observable, computed, action, autorun, reaction } from 'mobx';
import * as Api from 'utils/api/api';
import ShopType from 'enum/ShopType';

class ShopsStore {
  @observable _list = [];
  @observable.ref PAGE_SIZE = 8;
  @observable _pageSize = 8;
  @observable _pageNum = 1;
  @observable _goodsNum = 3;
  @observable _type = ShopType.All.value;
  @observable _descSort = undefined;
  @observable _servSort = undefined;
  @observable _lgstSort = undefined;
  @observable _refreshing = false;

  get list() {
    return this._list;
  }
  set list(value) {
    this._list = value;
  }
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value) {
    this._pageSize = value;
  }
  get pageNum() {
    return this._pageNum;
  }
  set pageNum(value) {
    this._pageNum = value;
  }
  get goodsNum() {
    return this._goodsNum;
  }
  set goodsNum(value) {
    this._goodsNum = value;
  }
  get type() {
    return this._type;
  }
  set type(value) {
    this._type = value;
  }
  get descSort() {
    return this._descSort;
  }
  set descSort(value) {
    this._descSort = value;
  }
  get servSort() {
    return this._servSort;
  }
  set servSort(value) {
    this._servSort = value;
  }
  get lgstSort() {
    return this._lgstSort;
  }
  set lgstSort(value) {
    this._lgstSort = value;
  }
  get refreshing() {
    return this._refreshing;
  }
  set refreshing(value) {
    this._refreshing = value;
  }

  /**
   * 获取列表
   */
  @action async fetchList() {
    try {
      this._refreshing = true;
      const { pageSize, pageNum, goodsNum, type, descSort, servSort } = this;

      const { list } = await Api.shopStreetList({
        pageSize,
        pageNum,
        goodsNum,
        type,
        descSort,
        servSort
      });

      this._list = list;
    } catch (error) {
    } finally {
      this._refreshing = false;
    }
  }
}

const store = new ShopsStore();

export default store;
