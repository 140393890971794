import React from 'react';
import './company.scss';
import Head from 'components/merchant/head/Head';
import Step from 'components/merchant/step/Step';
import BottomButton from 'components/merchant/bottom-button/BottomButton';
import { InputItem, Radio, List } from 'antd-mobile';
import { createForm } from 'rc-form';
import { inject, observer } from 'mobx-react';
import Gender from 'enum/Gender';

const Company = props => {
  const { merchant, form } = props;
  const { company } = merchant;
  const { getFieldProps } = form;

  return (
    <div className='glx-merchant-company'>
      <Head />
      <Step />
      <div className='content'>
        <div className='title-floor'>
          <div className='title'>联系方式</div>
          <div className='sub-title'>卖家入驻联系人信息</div>
        </div>

        <div className='container'>
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写真实姓名'
            value={company.name}
            onChange={value => {
              company.name = value;
            }}
          >
            姓名
          </InputItem>
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写手机号'
            value={company.mobilephone}
            onChange={value => {
              company.mobilephone = value;
            }}
            type='phone'
          >
            手机
          </InputItem>
          <List.Item>
            <span className='label'>性别</span>
            <Radio
              name='gender'
              checked={company.gender === Gender.Male}
              onChange={e => {
                company.gender = Gender.Male;
              }}
            >
              男
            </Radio>
            <Radio
              name='gender'
              checked={company.gender === Gender.Female}
              onChange={e => {
                company.gender = Gender.Female;
              }}
            >
              女
            </Radio>
          </List.Item>
        </div>
      </div>
      <BottomButton className='bottom-btn' />
    </div>
  );
};

export default createForm()(inject('merchant')(observer(Company)));
