import React, { Component } from 'react';

import './category-section.scss';
import { useHistory, NavLink } from 'react-router-dom';

const CategoryBgImg = require('./img/category-bg.png');

const CategorySection = props => {
  const { id, optId, optName, childList: childList, isSelf, hasSelf } = props;

  return (
    <div className='category-section'>
      <p className='category-section__title'>
        {/* <NavLink to={`/search/result?categoryId=${id}&isSelf=${isSelf}`}> */}
        <NavLink to={`/search/result?keyword=${optName}`}>{optName}</NavLink>
      </p>
      <div className='category-section__items'>
        {childList?.map((item, idx) => (
          <CategorySectionImg parentCategoryId={id} {...item} key={idx} />
        ))}
        <div className='clear-float' />
      </div>
    </div>
  );
};

const CategorySectionImg = props => {
  const { id, optName, isSelf, pic, parentCategoryId } = props;
  return (
    <div className='category-section__item'>
      {/* <NavLink
        to={`/search/result?categoryId=${parentCategoryId}&childCategoryId=${id}&isSelf=${isSelf}`}
      > */}
      <NavLink to={`/search/result?keyword=${optName}`}>
        {
          <div className='img-block'>
            <img src={CategoryBgImg} alt='' className='bg-img' />
            <div className='name'>{optName}</div>
          </div>
        }
        {/* <img className='category-section__img' alt='' />
        <span className='category-section__name'>{optName}</span> */}
      </NavLink>
    </div>
  );
};

export default CategorySection;
