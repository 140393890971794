import axios from 'axios';
import * as antd from 'antd';
// import qs from 'qs';
let history = require('history').createBrowserHistory();

let http = axios.create({
    timeout: 1000 * 60 * 2,
});

export const Header = {
    ContentType: 'content-type'
};
export const ContentType = {
    FormData: 'application/x-www-form-urlencoded;charset=UTF-8',
    JSON: 'application/json'
};

//配置全局axios
// http.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded;charset=UTF-8";
http.defaults.headers.post['Content-Type'] = ContentType.JSON;
http.defaults.headers.put['Content-Type'] = ContentType.JSON;
// 带cookie请求
http.defaults.withCredentials = true;

// 添加请求拦截器
http.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // 请求已发出，但服务器响应的状态码不在 2xx 范围内
        if (error && error.response) {
            const {response} = error;
            const {status, data, config, headers} = response;

            if (status === 426) {
                antd.message.error('登录失效');
                window.location.replace('/mobile/my/account/login');
            } else {
                let errorMessage = data.msg || data.ExceptionMsg || data;
                if (errorMessage) {
                    if (errorMessage.indexOf('请求超时') !== -1 || errorMessage.indexOf("gw-api.pinduoduo.com") !== -1) {
                        return http(config)
                    } else if (errorMessage.indexOf("拼多多") !== -1) {
                        return Promise.reject("拼多多");
                    } else {
                        antd.message.error(errorMessage);
                    }
                }
            }

        } else {
            if (error.message.indexOf("Network Error") !== -1) {
                return Promise.reject("网络错误");
            }
        }

        return Promise.reject(error);
    }
);

export default http;
