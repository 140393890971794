import { observable, action } from 'mobx';
import * as Api from 'utils/api/api';

class HelpInfo {
  @observable _info = null;

  get info() {
    return this._info;
  }
  set info(value) {
    this._info = value;
  }
  /**
   * 初始化
   */
  @action init() {
    this._info = null;
  }

  /**
   *
   * @param {*} id
   */
  @action async fetchInfo(id) {
    try {
      const { info } = await Api.helpInfo({ id });

      //   const { id, title, content, preArticle, nextArticle } = info;

      this._info = info;
    } catch (error) {}
  }
}

const store = new HelpInfo();

export default store;
