import { observable, computed, action } from "mobx";
import * as Api from "utils/api/api";

class VideoStore {
  @observable _list = [];
  @observable PAGE_SIZE = 8;
  @observable _pageSize = 8;
  @observable _pageNum = 1;
  @observable _title = "";
  @observable _currentCategoryIndex = 0;
  @observable _refreshing = false;

  @action init() {
    this._list = [];
    this._pageSize = 8;
    this._pageNum = 1;
    this._title = "";
    this._currentCategoryIndex = 0;
    this._refreshing = false;
  }

  @action async fetchList() {
    if (this._refreshing) {
      return;
    }
    try {
      this._refreshing = true;
      const { pageSize, pageNum, title } = this;
      const { list } = await Api.videoList({
        pageSize,
        pageNum,
        title,
      });

      this.list = list;
    } catch (error) {
    } finally {
      this._refreshing = false;
    }
  }

  get list() {
    return this._list;
  }
  set list(value) {
    this._list = value;
  }
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value) {
    this._pageSize = value;
  }
  get pageNum() {
    return this._pageNum;
  }
  set pageNum(value) {
    this._pageNum = value;
  }
  get title() {
    return this._title;
  }
  set title(value) {
    this._title = value;
  }
  get currentCategoryIndex() {
    return this._currentCategoryIndex;
  }
  set currentCategoryIndex(value) {
    this._currentCategoryIndex = value;
  }
  get refreshing() {
    return this._refreshing;
  }
  set refreshing(value) {
    this._refreshing = value;
  }
}

const store = new VideoStore();

export default store;
