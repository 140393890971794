import React, {useEffect, useRef} from 'react';
import './look-around-floor.scss'
import {inject, observer} from "mobx-react";
import showPrice from "utils/show-price";
import {useHistory} from 'react-router-dom'
import Loading from 'components/loading/Loading'

function LookAroundFloor({home}) {
    const {lookAround} = home;
    const history = useHistory();
    const goodsListContainer = useRef()

    const onScroll = e => {
        const rect = goodsListContainer.current.getBoundingClientRect()
        const top = rect.top
        const height = rect.height
        const clientHeight = document.body.clientHeight
        if (clientHeight - top > height && !home.lookAround.loading) {
            home.lookAround.pageSize += home.lookAround.PAGE_SIZE
        }
    }
    useEffect(() => {
        window.addEventListener('touchmove', onScroll)
        return () => {
            window.removeEventListener('touchmove', onScroll)
        }
    }, [])

    function renderGoodsList(goodsList) {
        const toGoodsInfo = (id, pddShop) => () => {
            history.push({
                pathname: `/detail/${id}`,
                search: `?type=${pddShop === true ? 1 : 0}`
            });
        }

        return goodsList.map(({id, mainPic, name, currentPrice, pddShop, isSpecialPrice, isHot}) => {

                let tagDom = null;
                if (pddShop) {
                    tagDom = (
                        <>
                            {isHot ? <img src={require('./img/hot-tag.png')} alt="" className="tag"/> : ""}
                            {isSpecialPrice ?
                                <img src={require('./img/special-price-tag.png')} alt="" className="tag"/> : ""}
                        </>
                    )
                } else {
                    tagDom = <img src={require('./img/self-tag.png')} alt="" className="tag"/>
                }

                return <div className="goods" key={id} onClick={toGoodsInfo(id, pddShop)}>
                    <div className="img-block">
                        <div className="img-wrapper">
                            <img src={mainPic} alt=""/>
                        </div>
                    </div>
                    <div className="info-block">
                        <div className="name">{name}</div>
                        <div className="price-floor">
                            <div className="current-price">{showPrice(currentPrice)}</div>
                            <div className="tag-block">
                                {tagDom}
                            </div>
                        </div>
                    </div>
                </div>
            }
        )
    }

    return <div className="glx-home-look-around-floor">
        <div className="container">
            <div className="title-floor">
                <div className="div title">随便看看</div>
            </div>
            <div className="goods-list" ref={goodsListContainer}>
                {renderGoodsList(lookAround.list)}
            </div>
        </div>
        <div className="load-more">
            {
                home.lookAround.loading
                    ? <Loading/>
                    : <div>上拉加载更多</div>
            }
        </div>
    </div>
}

export default inject('home')(observer((LookAroundFloor)))