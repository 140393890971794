import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';


/**
 * 顶部banner列表
 */
export const fetchHomeTopBannerList = (page = 1, size = 3, type = 1) => {
    return http({
        method: Method.POST,
        url: DOMAIN + '/app/img/list',
        data: {
            page,
            size,
            type
        }
    }).then(res => res.data)
        .then(data => data.msg.objectList)
};

export const advCarouselList = (page = 1, size = 10, type = 17) => {
    return http({
        method: Method.POST,
        url: DOMAIN + '/app/img/list',
        data: {
            page,
            size,
            type
        }
    }).then(res => res.data)
        .then(data => data.msg.objectList)
}

/**
 * 中间banner列表
 */
export const fetchHomeBodyBannerList = (page = 1, size = 10, type = 13) => {
    return http({
        url: DOMAIN + '/app/img/list',
        method: Method.POST,
        data: {
            page,
            size,
            type
        }
    }).then(res => res.data)
        .then(data => data.msg.objectList)
}
