import React from 'react'
import './swiper-floor1.scss'
import {inject, observer} from "mobx-react";

function SwiperFloor1({home}) {
    let {swiperList1: imgList} = home;

    function renderSwiper() {
        return imgList.map(({imgUrl, link}, index) => {
            const onClick = () => {
                window.open(link);
            }
            return (
                <div className="swiper-item" key={index} onClick={onClick}>
                    <img src={imgUrl} alt=""/>
                </div>
            )
        })
    }

    return (
        <div className="glx-home-swiper-floor1">
            {renderSwiper()}
        </div>
    )
}

export default inject('home')(observer(SwiperFloor1));