import React, {useEffect, useRef, useState} from 'react'
import './category-floor.scss'
import Swiper from 'swiper'
import {useHistory} from "react-router-dom";

function CategoryFloor({floor, index, toSearch}) {
    const swiperRef = useRef(null)
    const [currentIndex, setCurrentIndex] = useState(0);
    const {imgList, goodsListDTOS: goodsList} = floor;
    const history = useHistory();

    /*
    * 创建banner轮播
    * */
    useEffect(() => {
        if (!imgList?.length) {
            return;
        }

        let loop = true;
        if (imgList?.length === 1) {
            loop = false;
        }

        new Swiper(swiperRef.current, {
            loop,
            // autoplay: true,
            on: {
                slideChange() {
                    setCurrentIndex(this.activeIndex - 1);
                }
            }
        })
    }, [imgList])

    function renderBanner() {
        const onClick = (link) => () => {
            if (link) {
                window.open(link);
            }
        }
        return imgList?.map(({id, imgUrl, link}) => {
            return (
                <div className='swiper-slide' key={id} onClick={onClick(link)}>
                    <div className='img-block'>
                        <img src={imgUrl} alt='' className='img'/>
                    </div>
                </div>
            )
        })
    }

    function renderHotWordList() {
        return floor.hotWordList?.map((item, index) => {
            return (
                <div className='hot-word' key={index} onClick={toSearch(item)}>
                    {item}
                </div>
            )
        })
    }

    function renderGoodsList() {
        const toGoodsInfo = (id, pddShop) => () => {
            history.push({
                pathname: `/detail/${id}`,
                search: `?type=${pddShop === true ? 1 : 0}`
            });
        };

        return goodsList?.map(({id, mainPic, pddShop}) => {
            return (
                <div className="goods" onClick={toGoodsInfo(id, pddShop)} key={id}>
                    <img src={mainPic} alt="" className="main-img"/>
                </div>
            )
        })
    }

    return (
        <div className="glx-home-category-floor">
            <div className={`banner-container index-${index}`}>
                {/*进入频道*/}
                <div className={`enter index-${index}`} onClick={toSearch(floor.floorName)}>
                    <div className="enter-text">进入频道</div>
                    <img src={require('./img/enter-arrow.png')} alt="" className="enter-arrow"/>
                </div>

                {/*标题*/}
                <div className="title-floor">
                    <div className="title">{floor.floorName}</div>
                    <div className={`hot-word-block index-${index}`}>
                        {/*背景*/}
                        <img src={require(`./img/title-icon-${index + 1}.png`)} alt="" className='icon'/>
                        <div className="hot-word-list">
                            {renderHotWordList()}
                        </div>
                    </div>
                </div>
                {/*轮播*/}
                <div className="banner-floor">
                    <div className='swiper-container' ref={swiperRef}>
                        <div className='swiper-wrapper'>
                            {renderBanner()}
                        </div>
                    </div>
                </div>
            </div>

            <div className="goods-list-block">
                <div className="goods-list">
                    <div className="goods-list-container">
                        {renderGoodsList()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryFloor;