import {observable} from "mobx";

class LookAround {
    @observable _list = [];
    @observable _PAGE_SIZE = 6;
    @observable _pageSize = this._PAGE_SIZE;
    @observable _pageNum = 1;
    @observable _loading = false;

    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }

    get PAGE_SIZE() {
        return this._PAGE_SIZE;
    }

    set PAGE_SIZE(value) {
        this._PAGE_SIZE = value;
    }

    get pageSize() {
        return this._pageSize;
    }

    set pageSize(value) {
        this._pageSize = value;
    }

    get pageNum() {
        return this._pageNum;
    }

    set pageNum(value) {
        this._pageNum = value;
    }

    get loading() {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
    }
}

class PaySuccessStore {
    @observable lookAround = new LookAround();
}

export default new PaySuccessStore();