import React, {useEffect, useRef, useState} from 'react'
import './top-floor.scss'
import {inject, observer} from "mobx-react";
import Swiper from 'swiper'

function TopFloor({home}) {
    const swiperRef = useRef(null)
    const [currentIndex, setCurrentIndex] = useState(0);

    const {topBannerList: imgList} = home;

    useEffect(() => {
        if (!imgList.length) {
            return;
        }

        new Swiper(swiperRef.current, {
            loop: true,
            // autoplay: true,
            on: {
                slideChange() {
                    setCurrentIndex(this.activeIndex - 1);
                }
            }
        })
    }, [imgList])

    function renderBanner() {
        return imgList.map((item, index) => {
            return (
                <div className='swiper-slide' key={index} onClick={() => {
                    if (item.link) {
                        window.open(item.link)
                    }
                }}>
                    <div className='img-block'>
                        <img src={item.imgUrl} alt='' className='img'/>
                    </div>
                </div>
            )
        })
    }

    function renderPagination() {
        return imgList.map((item, index) => {
            return (
                <div className={`pagination-item ${index === currentIndex % imgList.length ? "active" : ""}`}
                     key={index}/>
            )
        })
    }

    return (
        <div className="glx-home-top-floor">
            <img src={process.env.PUBLIC_URL + "/img/home-bg/bg1.png"} alt="" className="bg"/>
            <div className="banner">
                <div className='swiper-container' ref={swiperRef}>
                    <div className='swiper-wrapper'>
                        {renderBanner()}
                    </div>
                </div>

                <div className="swiper-pagination">
                    {renderPagination()}
                </div>
            </div>
        </div>
    )
}

export default inject('home')(observer(TopFloor));