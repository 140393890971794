import { observable, action } from 'mobx';
import * as Api from 'utils/api/api';
import * as antd from 'antd';

function getVerifySrc() {
  return `${Api.VerifySrc}?date=${new Date().getTime()}`;
}

class ModifyPassword {
  @observable _password = '';
  @observable _password1 = '';
  @observable _password2 = '';
  @observable _verifyCode = '';
  @observable _verifyImg = getVerifySrc();

  get password() {
    return this._password;
  }
  set password(value) {
    this._password = value;
  }
  get password1() {
    return this._password1;
  }
  set password1(value) {
    this._password1 = value;
  }
  get password2() {
    return this._password2;
  }
  set password2(value) {
    this._password2 = value;
  }
  get verifyCode() {
    return this._verifyCode;
  }
  set verifyCode(value) {
    this._verifyCode = value;
  }
  get verifyImg() {
    return this._verifyImg;
  }
  set verifyImg(value) {
    this._verifyImg = value;
  }

  @action init() {
    this._password = '';
    this._password1 = '';
    this._password2 = '';
    this._verifyCode = '';
    this.refreshVerifyImg();
  }

  @action refreshVerifyImg() {
    this._verifyImg = getVerifySrc();
  }

  @action async doModify() {
    try {
      const { verifyCode, password, password1, password2 } = this;

      if (!password) {
        antd.message.error('请输入原密码');
        return false;
      }
      if (!password1) {
        antd.message.error('请输入新密码');
        return false;
      }
      if (password1 !== password2) {
        antd.message.error('两次输入的密码不一致');
        return false;
      }
      if (!this.verifyCode) {
        antd.message.error('请输入验证码');
        return false;
      }

      await Api.modifyPassword({
        verify: verifyCode,
        password,
        newPassword: password1
      });

      return true;
    } catch (error) {
      antd.message.error(error);
      return false;
    }
  }

  


}

const store = new ModifyPassword();

export default store;
