const type = {
    // 首页轮播图
    INDEX_IMG: 1,
    //   为你推荐图片
    RECOMMEND_IMG: 2,
    // 资讯图片
    NEWS_IMG: 3,
    //  顶部广告图
    TOP_IMG: 4,
    //   中部广告图
    CENTRAL_IMG: 5,
    //    底部广告图
    BOTTOM_IMG: 6,
    //   拼多多类目图
    CATEGORY_IMG: 7,
    //   类目右下角图
    CATEGORY_RIGHT_IMG: 8,
    //   自营图左1
    SELF_RUN_LEFT_IMG: 9,
    //   自营图左2
    SELF_RUN_LEFT_IMG2: 10,
    //  自营图右1
    SELF_RUN_RIGHT_IMG1: 11,
    //   自营图右2
    SELF_RUN_RIGHT_IMG2: 12,
    //   首页轮播下方下图
    UNDER_INDEX_SMALL: 13,
    //   首页logo
    INDEX_LOGO: 14,
    //   自营轮播
    SELF_RUN_INDEX: 15,
    //   专区
    SPECIAL: 16,
    // 广告轮播
    ADV_CAROUSEL: 17
};

export default type;
