import React, {useEffect, useMemo, useRef} from 'react'
import Loading from 'components/loading/Loading'
import useLoading from "hooks/useLoading";

const IfLoading = ({children, onLoad, loadCondition}) => {
    const ref = useRef();
    const loading = useLoading(ref);

    const innerLoading = useMemo(() => {
        if (loadCondition === undefined) {
            loadCondition = false;
        }

        return loading || loadCondition;
    }, [loading, loadCondition])

    useEffect(() => {
        if (!onLoad) {
            return;
        }

        if (!innerLoading) {
            onLoad()
        }
    }, [innerLoading])

    return (
        <div className='load-wrapper' ref={ref}>
            {innerLoading ? <Loading/> : children}
        </div>
    )
}

export default IfLoading;