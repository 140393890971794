import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import "styles/live-poster.scss";
import { useHistory } from "react-router-dom";
import * as Api from "utils/api/api";

const LivePoster = () => {
  const history = useHistory();

  const onSaveMiniCode = () => {
    Api.downloadMiniProgramCode();
  };

  return useObserver(() => (
    // <div id="glx-live-poster-page__mescroll" className="mescroll">
    <div className="glx-live-poster-page">
      <img src={require("images/live-poster/bg.png")} alt="" className="bg" />
      <div className="mini-program-code-container">
        <img
          src={Api.miniProgramCode({ scene: "tabIndex=1" })}
          alt="小程序二维码"
          className="img-mini-program-code"
        />
        <div className="text-tip">扫描二维码前往小程序</div>
      </div>
      <div className="bottom-panel-container">
        <div className="btn-save-container">
          <img
            src={require("images/live-poster/save-btn.png")}
            alt=""
            className="img-save"
            onClick={onSaveMiniCode}
          />
        </div>
        <div className="open-live-container">
          <div className="text">还没开通云直播？</div>
          <div
            className="text-link"
            onClick={() => {
              history.push({
                pathname: "/open-live-process",
              });
            }}
          >
            云直播开通流程
          </div>
        </div>

        <div
          className="enter-personal-container"
          onClick={() => history.goBack()}
        >
          <img
            src={require("images/live-poster/arrow-up.png")}
            alt=""
            className="icon-arrow-up"
          />
          <div className="text">个人中心</div>
          <img
            src={require("images/live-poster/arrow-up-shadow.png")}
            alt=""
            className="img-shadow"
          />
        </div>
      </div>
    </div>
    // </div>
  ));
};

export default LivePoster;
