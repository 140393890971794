import React, {useCallback, useEffect} from 'react'

import 'styles/home.scss'
import {inject, observer, useLocalStore} from 'mobx-react'
import {useHistory} from 'react-router-dom'

import Header from 'partials/home/header/Header'
import TopFloor from "partials/home/top-floor/TopFloor";
import AdvFloor1 from "partials/home/adv-floor1/AdvFloor1";
import NavFloor from "partials/home/nav-floor/NavFloor";
import SwiperFloor1 from "partials/home/swiper-floor1/SwiperFloor1";
import SeckillFloor from "partials/home/seckill-floor/SeckillFloor";
import NewsFloor from "partials/home/news-floor/NewsFloor";
import CategoryFloor from 'partials/home/category-floor/CategoryFloor';
import RecommendFloor from 'partials/home/recommend-floor/RecommendFloor';
import LookAroundFloor from "partials/home/look-around-floor/LookAroundFloor";
import * as Api from "utils/api/api";
import BottomNav from 'components/BottomNav'
import FetchDataStatus from "enum/FetchDataStatus";
import IfLoading from "components/if-loading/IfLoading";


function Home({home: store, search}) {
    const history = useHistory()


    const localStore = useLocalStore(() => ({
        newsListFetchDataStatus: FetchDataStatus.BEFORE_FETCH.value,
        floorList: [],
        onFetchingFloorData(id) {
            let tempFloorList = [...this.floorList]
            for (let i = 0; i < tempFloorList.length; i++) {
                if (tempFloorList[i].id === id) {
                    tempFloorList[i].fetchDataStatus = FetchDataStatus.FETCHING.value;
                    break;
                }
            }

            this.floorList = tempFloorList;
        },
        onHasFetchedFloorData(id) {
            let tempFloorList = [...this.floorList]
            for (let i = 0; i < tempFloorList.length; i++) {
                if (tempFloorList[i].id === id) {
                    tempFloorList[i].fetchDataStatus = FetchDataStatus.AFTER_FETCH.value;
                    break;
                }
            }

            this.floorList = tempFloorList;
        },
        recommendListFetchDataStatus: FetchDataStatus.BEFORE_FETCH.value,
        showLookingAroundFloor: false
    }))

    const fetchFloorInfo = async (id) => {
        if (!id) {
            return;
        }

        /*
        * 如果当前楼层正在请求数据，则退出函数
        * */
        for (let i = 0; i < localStore.floorList.length; i++) {
            if (id === localStore.floorList[i].id) {
                if (localStore.floorList[i].fetchDataStatus === FetchDataStatus.FETCHING.value) {
                    return;
                }
            }
        }


        localStore.onFetchingFloorData(id);
        const floor = await Api.floorInfo({id})
        localStore.onHasFetchedFloorData(id);
        store.modifyGoodsFloorList(floor)
    }

    /**
     * 顶部banner
     */
    useEffect(() => {
        Api.fetchHomeTopBannerList().then(list => {
            store.topBannerList = list;
        })
    }, []);

    /**
     * 广告楼层1轮播
     */
    useEffect(() => {
        Api.advCarouselList().then(list => {
            store.advList1 = list;
        })
    }, [])

    /**
     * 轮播楼层1
     */
    useEffect(() => {
        Api.fetchHomeBodyBannerList()
            .then(list => {
                store.swiperList1 = list;
            })
    }, [])

    /**
     * 秒杀
     */
    useEffect(() => {
        Api.secKillList({pageNum: 1, pageSize: 10})
            .then(({list}) => {
                store.seckillList = list;
            })
    }, [])

    /**
     * 新闻列表
     */
    useEffect(() => {
        localStore.newsListFetchDataStatus = FetchDataStatus.FETCHING.value
        Api.newsList({
            pageNum: 1,
            pageSize: 10,
            type: 1
        }).then(({list}) => {
            store.newsList = list;
            localStore.newsListFetchDataStatus = FetchDataStatus.AFTER_FETCH.value

            Api.floorList().then(simpleFloorList => {
                store.categoryFloorList = simpleFloorList;

                localStore.floorList = simpleFloorList.map(({id}) => ({
                    id,
                    fetchDataStatus: FetchDataStatus.BEFORE_FETCH.value
                }))

                fetchFloorInfo(store.categoryFloorList?.[0]?.id).then()
            })
        })
    }, [])

    /**
     * 为你推荐
     */
    const fetchRecommendList = () => {
        localStore.recommendListFetchDataStatus = FetchDataStatus.FETCHING.value
        Api.recommendList({
            pageSize: 8,
            pageNum: 1
        }).then(({list}) => {
            store.recommendList = list;
            localStore.recommendListFetchDataStatus = FetchDataStatus.AFTER_FETCH.value
        })
    }

    /**
     * 随便看看
     */
    const fetchLookAround = useCallback(() => {
        if (!localStore.showLookingAroundFloor) {
            return;
        }

        store.lookAround.loading = true;
        Api.recommendList({
            pageSize: store.lookAround.pageSize,
            pageNum: store.lookAround.pageNum,
        }).then(({list}) => {
            store.lookAround.list = list.slice(0, store.lookAround.pageSize);
        }).finally(() => {
            store.lookAround.loading = false;
        })
    }, [store.lookAround.pageSize, localStore.showLookingAroundFloor])
    useEffect(() => {
        fetchLookAround();
    }, [fetchLookAround]);

    const toSearch = keyword => () => {
        search.keyword = keyword;
        history.push({
            pathname: `/search/result`
        });
    };

    const renderFloorList = () => {
        if (!localStore.floorList) {
            return;
        }

        return store.categoryFloorList.map((item, index, arr) => {
            if (index === 0) {
                if (localStore.newsListFetchDataStatus !== FetchDataStatus.AFTER_FETCH.value) {
                    return;
                }
            } else {
                if (localStore.floorList?.[index - 1]?.fetchDataStatus !== FetchDataStatus.AFTER_FETCH.value) {
                    return
                }
            }

            let onLoad;
            if (index < arr.length - 1) {
                onLoad = () => fetchFloorInfo(arr[index + 1].id);
            } else {
                onLoad = () => fetchRecommendList();
            }

            return (
                <IfLoading onLoad={onLoad}
                           loadCondition={localStore.floorList?.[index]?.fetchDataStatus !== FetchDataStatus.AFTER_FETCH.value}
                           key={item.id}>
                    <CategoryFloor
                        floor={item}
                        index={index}
                        key={index}
                        toSearch={toSearch}
                    />
                </IfLoading>)
        })
    }

    return (
        <div className='home'>
            {/*header*/}
            <Header/>
            {/*顶部banner*/}
            <TopFloor/>
            {/*广告轮播*/}
            <AdvFloor1/>
            {/*导航栏*/}
            <NavFloor/>
            {/*轮播楼层1*/}
            <SwiperFloor1/>
            {/*秒杀*/}
            <SeckillFloor/>
            {/*新闻*/}
            <NewsFloor/>
            {/*楼层*/}
            {renderFloorList()}
            {/*为你推荐*/}
            {
                localStore.floorList?.[localStore.floorList.length - 1]?.fetchDataStatus === FetchDataStatus.AFTER_FETCH.value &&
                <IfLoading
                    onLoad={() => localStore.showLookingAroundFloor = true}
                    loadCondition={localStore.recommendListFetchDataStatus !== FetchDataStatus.AFTER_FETCH.value}>
                    <RecommendFloor/>
                </IfLoading>
            }
            {/*随便看看*/}
            {
                localStore.showLookingAroundFloor === true &&
                <LookAroundFloor/>
            }

            {/*导航栏*/}
            <BottomNav/>
        </div>
    )
}

export default inject('service', 'app', 'home', 'search')(observer(Home))
