import { observable, action, reaction } from 'mobx';
import * as Api from '../utils/api/api';

class CategoryStore {
  @observable _categoryList = [];
  @observable _hasSelf = false;
  @observable _currentIndex = -1;
  @observable _childCategoryList = [];

  get categoryList() {
    return this._categoryList;
  }
  set categoryList(value) {
    this._categoryList = value;
  }

  get hasSelf() {
    return this._hasSelf;
  }
  set hasSelf(value) {
    this._hasSelf = value;
  }
  get currentIndex() {
    return this._currentIndex;
  }
  set currentIndex(value) {
    this._currentIndex = value;
  }

  get currentCategory() {
    if (this._currentIndex < 0) {
      return null;
    } else {
      return this._categoryList[this._currentIndex];
    }
  }
  get childCategoryList() {
    return this._childCategoryList;
  }
  set childCategoryList(value) {
    this._childCategoryList = value;
  }

  @action init() {
    this._childCategoryList = [];
    this._currentIndex = -1;
  }

  @action async fetchCategoryList() {
    let list = [];

    const {
      list: pddGoodsCategoryList,
      hasSelfCategory
    } = await Api.pddGoodsCategoryList({
      parentId: 0
    });
    list.push(...pddGoodsCategoryList);

    let selfItem = {
      id: 0,
      optName: '平台自营',
      isSelf: true,
      icon: '',
      iconActive: ''
    };

    if (hasSelfCategory) {
      selfItem.hasSelf = true;
    } else {
      selfItem.hasSelf = false;
    }
    list = [selfItem, ...list];

    this._categoryList = list;

    if (list.length > 0) {
      this._currentIndex = 0;
    }
  }

  @action async fetchChildCategoryList() {
    let {
      id: parentId,
      optId: parentOptId,
      optName: parentName,
      isSelf,
      hasSelf,
      icon,
      iconActive
    } = this.currentCategory;

    let childList = [];

    if (isSelf) {
      let { list } = await Api.allChildGoodsCategoryList();
      if (list.length === 0) {
        list.push({
          id: parentId,
          optName: parentName,
          isSelf,
          hasSelf
        });
      } else {
        list = list.map(item => {
          return {
            ...item,
            hasSelf
          };
        });
      }
      childList.push(...list);
    } else {
      const { list } = await Api.pddGoodsCategoryListByParentId({
        parentId,
        parentOptId,
      });
      if (list.length === 0) {
        list.push({
          id: parentId,
          optId: parentOptId,
          optName: parentName,
          isSelf
        });
      }
      childList.push(...list);
    }

    this._childCategoryList = childList;
  }
}

const store = new CategoryStore();

reaction(
  () => store.currentCategory,
  currentCategory => {
    if (!currentCategory) {
      return;
    }
    store.fetchChildCategoryList(currentCategory);
  }
);

export default store;
