const type = {
    BEFORE_FETCH: {
        value: 1
    },
    FETCHING: {
        value: 2
    },
    AFTER_FETCH: {
        value: 3
    },
    getByValue(value) {
        for (let key in type) {
            if (type[key].value === value) {
                return type[key];
            }
        }
    }
};

Object.defineProperty(type, 'getByValue', {
    enumerable: false
});

export default type;
