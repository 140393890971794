import React, {useEffect, useMemo, useState} from 'react'
import './seckill-floor.scss'
import {inject, observer} from "mobx-react";
import showPrice from "utils/show-price";
import {useHistory} from 'react-router-dom';
import useSecKill from "hooks/useSecKill";

function SeckillFloor({home}) {
    const history = useHistory();
    const seckill = useSecKill()

    const [time, setTime] = useState({
        hour: 0,
        minute: 0,
        second: 0
    });

    useEffect(() => {
        const timer = setInterval(() => {
            const seckillTime = seckill.getTime();
            setTime({
                // ...time,
                hour: seckillTime.hour,
                minute: seckillTime.minute,
                second: seckillTime.second
            })
        }, 1000)

        return () => {
            clearInterval(timer);
        }
    }, [seckill]);


    function renderSeckillList() {
        return home.seckillList?.map(({goodsId, mainPic, currentPrice, originPrice, pddShop}) => {
            const toInfo = (goodsId, pddShop) => () => {
                history.push({
                    pathname: `/detail/${goodsId}`,
                    search: `?type=${pddShop === true ? 1 : 0}`
                })
            }
            return (
                <div className="goods" key={goodsId} onClick={toInfo(goodsId, pddShop)}>
                    <div className="img-block">
                        <img src={mainPic} alt="" className='main-img'/>
                    </div>
                    <div className="info">
                        <div className="current-price">{showPrice(currentPrice)}</div>
                        <div className="origin-price">{showPrice(originPrice)}</div>
                    </div>
                </div>
            )
        });
    }

    const toSeckill = () => {
        history.push('flash-sale');
    }

    return (
        <div className="glx-home-seckill-floor">
            <div className="container">
                <div className="title-block">
                    <img src={require('./img/title.png')} alt="" className="title"/>
                    <div className="left-time">
                        <div className="label">本场剩余时间</div>
                        <div className="time">{time.hour}:{time.minute}:{time.second}</div>
                    </div>
                </div>
                <div className="enter-block" onClick={toSeckill}>
                    <img src={require('./img/enter-star.png')} alt="" className="enter-star"/>
                    <span className="enter-text">进入专场</span>
                    <img src={require('./img/enter-arrow.png')} alt="" className="enter-arrow"/>
                </div>
                <div className="goods-list">
                    {renderSeckillList()}
                </div>
            </div>
        </div>
    )
}

export default inject('home')(observer(SeckillFloor));