import React from 'react'
import './nav-floor.scss'
import {useHistory} from 'react-router-dom'

function NavFloor() {

    const history = useHistory();

    const navList = [
        {
            icon: process.env.PUBLIC_URL + "/img/home-nav/self-icon.png",
            text: "平台自营",
            pathname: "/search/result"
        }, {
            icon: process.env.PUBLIC_URL + "/img/home-nav/seckill-icon.png",
            text: "限时秒杀",
            pathname: "/flash-sale"
        }, {
            icon: process.env.PUBLIC_URL + "/img/home-nav/shop-icon.png",
            text: "店铺街",
            pathname: "/shops"
        }, {
            icon: process.env.PUBLIC_URL + "/img/home-nav/news-icon.png",
            text: "新闻资讯",
            pathname: "/news"
        }, {
            icon: process.env.PUBLIC_URL + "/img/home-nav/merchant-icon.png",
            text: "招商入驻",
            pathname: "/merchant"
        }
    ]

    function renderNav() {
        return navList.map(({icon, text, pathname}, index) => {
            const onClick = () => history.push(pathname)
            return (
                <div className="nav-item" key={index} onClick={onClick}>
                    <img src={icon} alt="" className="icon"/>
                    <span className="text">{text}</span>
                </div>
            )
        })
    }

    return (
        <div className="glx-home-nav-floor">
            {renderNav()}

            {/* 背景 */}
            <img src={process.env.PUBLIC_URL + "/img/home-bg/bg2.png"} alt="" className='bg'/>
        </div>
    )
}

export default NavFloor;