import AppStore from './AppStore';
import HomeStore from './HomeStore';
import SearchStore from './SearchStore';
import DetailStore from './DetailStore';
import ShopStore from './ShopStore';
import ShopsStore from './ShopsStore';
import CategoryStore from './CategoryStore';
import VideoStore from './VideoStore';
import MyStore from './MyStore';
import login from './LoginStore';
import account from './AccountStore';
import modifyPassword from './ModifyPasswordStore';
import goodsCollect from './GoodsCollectStore';
import shopCollect from './ShopCollectStore';
import track from './TrackStore';
import cart from './CartStore';
import confirmGoods from './ConfirmGoodsStore';
import flashSale from './FlashSaleStore';
import news from './NewsStore';
import service from './ServiceStore';
import help from './HelpStore';
import helpInfo from './HelpInfoStore';
import merchant from './merchant/merchantStore';
import newsInfo from './NewsInfoStore';
import register from './RegisterStore';
import deal from './DealStore';
import order from './OrderStore'
import address from './AddressStore'
import orderList from './OrderListStore';
import afterSale from './AfterSaleStore';
import modifyOrder from "./ModifyOrderStore";
import addAddress from './AddAddressStore';
import modifyAddress from './ModifyAddressStore';
import selectOrderAddress from './SelectOrderAddressStore';
import orderPay from './OrderPayStore'
import constant from './ConstantStore'
import paySuccess from './PaySuccessStore'
import live from 'stores/liveStore'
import liveRoom from "stores/LiveRoomStore";

export default {
    app: AppStore,
    search: SearchStore,
    home: HomeStore,
    detail: DetailStore,
    shop: ShopStore,
    shops: ShopsStore,
    category: CategoryStore,
    video: VideoStore,
    my: MyStore,
    login,
    account,
    modifyPassword,
    goodsCollect,
    shopCollect,
    track,
    cart,
    confirmGoods,
    flashSale,
    news,
    service,
    help,
    helpInfo,
    merchant,
    newsInfo,
    register,
    deal,
    order,
    address,
    orderList,
    afterSale,
    modifyOrder,
    addAddress,
    modifyAddress,
    selectOrderAddress,
    orderPay,
    constant,
    paySuccess,
    live,
    liveRoom
}
