import { observable, ObservableMap, action, computed } from 'mobx'
import AppStore from './AppStore'
import * as Api from 'utils/api/api'

class Track {
  @observable _map = null
  @observable PAGE_SIZE = 8
  @observable _pageSize = 8
  @observable _pageNum = 1
  @observable _date = new Date()
  @observable _showMask = false
  @observable _refreshing = false

  get map() {
    return this._map
  }
  set map(value) {
    this._map = value
  }
  get pageSize() {
    return this._pageSize
  }
  set pageSize(value) {
    this._pageSize = value
  }
  get pageNum() {
    return this._pageNum
  }
  set pageNum(value) {
    this._pageNum = value
  }
  get date() {
    return this._date
  }
  set date(value) {
    this._date = value
  }
  get showMask() {
    return this._showMask
  }
  set showMask(value) {
    this._showMask = value
  }
  get refreshing() {
    return this._refreshing
  }
  set refreshing(value) {
    this._refreshing = value
  }

  /**
   * 初始化 show handle
   */
  @action initShowHandle() {
    let { map } = this
    for (let key in map) {
      map[key].forEach(item => {
        item.showHandle = false
      })
    }
  }

  /**
   * 获取信息
   */
  @action async fetchMap() {
    try {
      if (AppStore.hasLogin) {
        const { pageSize, pageNum } = this
        const { map, totalNum } = await Api.trackMap({
          pageSize,
          pageNum
        })

        this._map = map

        this.initShowHandle()
      }
    } catch (error) {}
  }
}

const store = new Track()

export default store
