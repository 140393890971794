import React from 'react'
import './recommend-floor.scss'
import {inject, observer} from "mobx-react";
import {useHistory} from 'react-router-dom'

function RecommendFloor({home}) {
    const {recommendList} = home;
    const history = useHistory();

    function renderSwiper(recommendList) {
        const toGoodsInfo = (id, pddShop) => () => {
            history.push({
                pathname: `/detail/${id}`,
                search: `?type=${pddShop === true ? 1 : 0}`
            });
        }

        let length = 8;
        if (recommendList.length < 8) {
            length = recommendList.length;
        }

        return recommendList.slice(0, length)
            .map(({id, mainPic, pddShop}) => {
                return <div className="goods" key={id} onClick={toGoodsInfo(id, pddShop)}>
                    <img src={mainPic} alt=""/>
                </div>
            })
    }

    return <div className="glx-home-recommend-floor">
        <div className="container">
            <div className="title-floor">
                <img src={require('./img/title.png')} alt="" className="title"/>
            </div>
            <div className="swiper-floor">
                {renderSwiper(recommendList)}
            </div>
        </div>
    </div>
}

export default inject("home")(observer(RecommendFloor));