import TIM from "tim-js-sdk";
import * as Api from "./api/api";
import * as Antd from "antd";
import TimCustomerMessageType from "enum/TimCustomeMessageType";

export default TIM;

/*
 * 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
 * SDK 实例通常用 tim 表示
 * */
export const tim = TIM.create({
    SDKAppID: "1400443552",
});

/*
 * 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
 * 普通级别，日志量较多，接入时建议使用
 * */
tim.setLogLevel(0);
/*
 * release级别，SDK 输出关键信息，生产环境时建议使用
 * */
// tim.setLogLevel(1);

/**
 * 获取
 * @param groupId
 * @returns {string}
 */
export const getConversationId = (groupId) => {
    return `GROUP${groupId}`;
};

/**
 * 登录
 * @param userId userId
 */
export const login = ({userId}) => {
    return Api.imUserSign({
        userId,
    })
        .then((userSig) => {
            tim
                .login({
                    userID: userId,
                    userSig,
                })
                .then((loginRes) => {
                    /*
                     * 标识账号已登录，本次登录操作为重复登录
                     * */
                    if (loginRes.data.repeatLogin === true) {
                    }
                });
        })
        .catch((errMsg) => {
            Antd.message.error(`弹幕系统登录失败:${errMsg}`);
        });
};

/**
 * 退出登录
 * @returns {*}
 */
export const logout = () => {
    return tim.logout();
};

/**
 * 获取群组详情
 * @param groupId groupId
 * @returns {Q.Promise<any> | Promise<void>}
 */
export const getGroupProfile = ({groupId}) => {
    return tim
        .getGroupProfile({
            groupID: groupId,
        })
        .then((res) => {
            return res.data.group;
        })
        .catch((errMsg) => {
            Antd.message.error(`获取弹幕群组详情失败:[${errMsg}]`);
        });
};

/**
 * 获取弹幕成员列表
 * @param groupId
 * @param page
 * @param size
 */
export const getGroupMemberList = ({groupId}) => {
    return tim
        .getGroupMemberList({
            groupID: groupId,
            count: 100,
            offset: 0,
        })
        .then((res) => res.data)
        .then((data) => data.memberList)
        .catch((errMsg) => {
            Antd.message.error(`获取弹幕成员列表失败:${errMsg}`);
        });
};

/**
 * 加入群组
 * @param groupId
 * @returns {Q.Promise<any> | Promise<void>}
 */
export const joinGroup = ({groupId}) => {
    return tim
        .joinGroup({
            groupID: groupId,
            type: TIM.TYPES.GRP_AVCHATROOM,
        })
        .then((res) => res.data)
        .then((data) => {
            switch (data.status) {
                case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                    return "等待管理员同意";
                case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                    return {
                        groupId: data.group.groupID,
                    };
                case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
                    return "已经在群中";
                default:
                    break;
            }
        })
        .catch((errMsg) => {
            Antd.message.error(`申请加群失败:${errMsg}`);
        });
};

/**
 * 退出群组
 * @param groupId
 * @returns {Q.Promise<any> | Promise<void>}
 */
export const quitGroup = ({groupId}) => {
    return tim
        .quitGroup(groupId)
        .then((res) => res.data)
        .then((data) => {
            return {
                groupId: data.groupID,
            };
        })
        .catch((errMsg) => {
            Antd.message.error(`退出群聊失败:${errMsg}`);
        });
};

/**
 * 获取会话资料
 * @param groupId
 * @returns {Q.Promise<any> | Promise<void>}
 */
export const getConversationProfile = ({groupId}) => {
    return tim
        .getConversationProfile(`GROUP${groupId}`)
        .then((res) => res.data)
        .then((data) => {
            return data.conversation;
        })
        .catch(function (errMsg) {
            Antd.message.error(`获取会话详情失败:${errMsg}`);
        });
};

/**
 * 内部发送消息方法
 * @param message
 * @returns {Promise<T>}
 */
function innerSendMessage(message) {
    return tim
        .sendMessage(message)
        .then(() => {
            return message;
        })
        .catch((errMsg) => {
            Antd.message.error(`发送消息失败:${errMsg}`);

            return Promise.resolve();
        });
}

/**
 * 发送消息
 * @param groupId
 * @param text
 * @returns {Promise<T>}
 */
export const sendMessage = ({groupId, text}) => {
    const message = tim.createTextMessage({
        to: groupId,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
            text,
        },
    });

    return innerSendMessage(message);
};

/**
 * 发送礼物
 * @param groupId 群组id
 * @param giftId 礼物id
 * @returns {Promise<T>}
 */
export const sendGift = ({groupId, giftId}) => {
    const message = tim.createCustomMessage({
        to: groupId,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
            data: giftId.toString(),
            description: "送礼物",
            extension: TimCustomerMessageType.SEND_GIFT.value.toString(),
        },
    });

    return innerSendMessage(message);
};

/**
 * 获取用户资料
 * @param userId 用户id
 * @returns {Q.Promise<any> | Promise<void> | undefined}
 */
export const getUserProfile = (userId) => {
    return tim
        .getUserProfile({
            userIDList: [userId],
        })
        .then((res) => {
            const [profile] = res.data;
            return profile;
        })
        .catch((errMsg) => {
            Antd.message.error(`获取im用户资料失败:${errMsg}`);
        });
};

/**
 * 获取自己的用户资料
 * @returns {Q.Promise<any> | Promise<void> | undefined}
 */
export const getMyProfile = () => {
    return tim
        .getMyProfile()
        .then((res) => res.data)
        .catch((errMsg) => {
            Antd.message.error(`获取自己的用户资料失败:${errMsg}`);
        });
};

/**
 * 更新用户资料
 * @param nickname 昵称
 * @param avatar 头像
 * @param gender 性别
 * @returns {Q.Promise<any> | Promise<void>}
 */
export const updateMyProfile = ({nickname, avatar, gender}) => {
    return tim
        .updateMyProfile({
            nick: nickname,
            avatar,
            gender,
        })
        .then((res) => res.data)
        .catch((errMsg) => {
            Antd.message.error(`更新im用户资料失败:${errMsg}`);
        });
};
