import {observable} from "mobx";

class AfterSaleStore {
    @observable _id = 0;
    @observable _orderId = 0;
    @observable _reason = '';
    @observable _status = undefined;
    @observable _denialReason = '';
    @observable _createTime = 0;
    @observable _afterSaleImgList = [];
    @observable _order = undefined;
    @observable _contact = undefined;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get orderId() {
        return this._orderId;
    }

    set orderId(value) {
        this._orderId = value;
    }

    get reason() {
        return this._reason;
    }

    set reason(value) {
        this._reason = value;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value;
    }

    get denialReason() {
        return this._denialReason;
    }

    set denialReason(value) {
        this._denialReason = value;
    }

    get createTime() {
        return this._createTime;
    }

    set createTime(value) {
        this._createTime = value;
    }

    get afterSaleImgList() {
        return this._afterSaleImgList;
    }

    set afterSaleImgList(value) {
        this._afterSaleImgList = value;
    }

    get order() {
        return this._order;
    }

    set order(value) {
        this._order = value;
    }

    get contact() {
        return this._contact;
    }

    set contact(value) {
        this._contact = value;
    }
}

export default new AfterSaleStore();