import {action, computed, observable} from "mobx";
import TIM from "utils/tim";
import React from "react";

class LiveRoomStore {
    @observable _playing = false;
    @observable _startingPlay = false;
    @observable _fullScreen = false;
    @observable _timReady = false;
    @observable _group = new GroupStore();
    @observable _live = undefined;
    @observable _liveTask = undefined;
    @observable _player = undefined;
    @observable _conversationProfile = undefined;
    @observable _messageList = [];
    @observable _bulletComment = '';
    @observable _currentGoods = undefined;

    @action
    init() {
        this._playing = false
        this._startingPlay = false
        this._fullScreen = false
        this._timReady = false
        this._group = new GroupStore()
        this._live = undefined
        this._liveTask = undefined
        this._player = undefined
        this._conversationProfile = undefined
        this._messageList = []
        this._bulletComment = ''
        this._currentGoods = undefined
    }

    @action
    putMessage(message) {
        this._messageList = [...this._messageList, message]
    }

    @action
    putMessageList(messageList) {
        this._messageList = [...this._messageList, ...messageList]
    }

    @action
    removeMessage(id) {
        this._messageList = [...this._messageList].filter(item => item.ID !== id)
    }


    get playing() {
        return this._playing;
    }

    set playing(value) {
        this._playing = value;
    }

    get startingPlay() {
        return this._startingPlay;
    }

    set startingPlay(value) {
        this._startingPlay = value;
    }

    get fullScreen() {
        return this._fullScreen;
    }

    set fullScreen(value) {
        this._fullScreen = value;
    }

    get timReady() {
        return this._timReady;
    }

    set timReady(value) {
        this._timReady = value;
    }

    get group() {
        return this._group;
    }

    set group(value) {
        this._group = value;
    }

    get live() {
        return this._live;
    }

    set live(value) {
        this._live = value;
    }

    get liveTask() {
        return this._liveTask;
    }

    set liveTask(value) {
        this._liveTask = value;
    }

    get player() {
        return this._player;
    }

    set player(value) {
        this._player = value;
    }

    get conversationProfile() {
        return this._conversationProfile;
    }

    set conversationProfile(value) {
        this._conversationProfile = value;
    }

    get messageList() {
        return this._messageList;
    }

    set messageList(value) {
        this._messageList = value;
    }

    get bulletComment() {
        return this._bulletComment;
    }

    set bulletComment(value) {
        this._bulletComment = value;
    }

    get currentGoods() {
        return this._currentGoods;
    }

    set currentGoods(value) {
        this._currentGoods = value;
    }
}


class GroupStore {
    @observable _memberList = [];
    @observable _info = undefined;

    get memberList() {
        return this._memberList;
    }

    set memberList(value) {
        this._memberList = value;
    }

    get info() {
        return this._info;
    }

    set info(value) {
        this._info = value;
    }
}

export default new LiveRoomStore();