import {observable} from "mobx";

class LiveStore {
    @observable _video = new VideoStore();
    @observable _list = [];
    @observable _currentIndex = 0;

    get video() {
        return this._video;
    }

    set video(value) {
        this._video = value;
    }

    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }

    get currentIndex() {
        return this._currentIndex;
    }

    set currentIndex(value) {
        this._currentIndex = value;
    }



    get group() {
        return this._group;
    }

    set group(value) {
        this._group = value;
    }
}

class VideoStore {
    @observable _list = [];

    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }
}


export default new LiveStore();