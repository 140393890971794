import React from 'react';

import './search-input.scss';
import SearchIcon from '../../../images/home/search.png';
import ClearIcon from 'images/search/clear.png';

interface SearchInputProps {
  keyword: string;
  onChange?: Function;
  onInput?: Function;
  onClick?: Function;
  onBlur?: Function;
  onSubmit?: Function;
  onClear?: Function;
  placeholder?: String;
}

const handlerOf = handler => {
  if (!handler) return e => e.preventDefault();
  else return handler;
};

function SearchInput(props: SearchInputProps) {
  const {
    keyword,
    onChange,
    onInput,
    onClick,
    onBlur,
    onSubmit,
    onClear,
    placeholder
  } = props;
  return (
    <form className='search-input' onSubmit={handlerOf(onSubmit)}>
      <img
        className='search-input__icon'
        src={SearchIcon}
        alt='search'
        onClick={handlerOf(onSubmit)}
      />
      <input
        value={keyword || ''}
        placeholder={placeholder || '发现好货品质'}
        className='search-input__input'
        onChange={handlerOf(onChange)}
        onInput={handlerOf(onInput)}
        onClick={handlerOf(onClick)}
        onBlur={handlerOf(onBlur)}
        onSubmit={handlerOf(onSubmit)}
      />
      {keyword ? (
        <img
          className='search-input__clear'
          src={ClearIcon}
          alt='x'
          onClick={handlerOf(onClear)}
        />
      ) : (
        <i className='none' />
      )}
    </form>
  );
}

export default SearchInput;
