import {action, computed, observable} from 'mobx'
import * as net from '../utils/networks'
import * as Api from 'utils/api/api'
import CollectEnum from 'enum/CollectEnum'

class DetailStore {
    @observable.ref _goodsId = 0
    @observable.ref _type = 0
    @observable.ref _num = 1
    // @observable _product = null
    @observable _skuList = [];
    @observable _currentSku = null;
    @observable _goods = null
    @observable _hasCollect = false
    @observable _collectId = 0
    @observable _goodsAttributeList = []
    @observable _specificationMapList = {}
    @observable _goodsPromotionUrl = ''
    @observable _description = ''
    @observable _cartCount = 0
    @observable _goodsPropList = []

    /**
     * 初始化
     */
    @action init() {
        this._goodsId = 0
        this._type = 0
        this._num = 1
        this._skuList = [];
        this._currentSku = null
        this._goods = null
        this._hasCollect = false
        this._collectId = 0
        this._goodsAttributeList = []
        this._specificationMapList = []
        this._goodsPromotionUrl = ''
        this._description = ''
    }

    /**
     * 获取信息
     */
    @action
    async fetchInfo() {
        try {
            const {goodsId, type} = this

            let {
                skuList,
                goods,
                hasCollect,
                collectId,
                goodsAttributeList,
                specificationMapList,
                goodsPromotionUrl
            } = await Api.goodsInfo({goodsId, type});


            if (skuList && skuList.length) {

                let currentSku = skuList.find(sku => sku.id === goods.primaryProductId);
                /**
                 * 设置规格
                 */
                let selectedSpecificationIdList = currentSku.goodsSpecificationIds
                    .split("_")
                    .map(id => Number(id));

                let mapList = [];
                for (let key in specificationMapList) {
                    let specificationList = specificationMapList[key];

                    let map = {};
                    map.list = [...specificationList];
                    map.currentIndex = 0;

                    for (let i = 0; i < specificationList.length; i++) {
                        if (selectedSpecificationIdList.indexOf(specificationList[i].id) !== -1) {
                            map.currentIndex = i;
                            break;
                        }
                    }

                    mapList[key] = map;
                }

                this._skuList = skuList;
                this._currentSku = currentSku;
                this._specificationMapList = {...mapList};
                this._hasCollect = hasCollect;
                this._collectId = collectId;
            }

            if (goods.description) {
                this._description = goods.description;
            }
            if (goodsPromotionUrl) {
                this._goodsPromotionUrl = goodsPromotionUrl;
            }

            if (goods) {
                this._goods = goods;
            }
            if (goodsAttributeList) {
                this._goodsAttributeList = goodsAttributeList;
            }
        } catch (e) {
        }
    }

    @action
    selectSpecification({key, index}) {
        let specificationMapList = {...this._specificationMapList};
        specificationMapList[key].currentIndex = index;
        this._specificationMapList = specificationMapList;

        let currentSpecificationIdList = [];
        for (let key in specificationMapList) {
            let value = specificationMapList[key];
            currentSpecificationIdList.push(value.list[value.currentIndex].id);
        }

        this._currentSku = this._skuList.find(sku => {
            let skuSpecificationIdList = sku.goodsSpecificationIds.split("_").map(item => Number(item));

            let isOk = true;
            currentSpecificationIdList.forEach(currentSpecificationId => {
                if (!skuSpecificationIdList.includes(currentSpecificationId)) {
                    isOk = false;
                }
            })

            return isOk;
        })
    }

    @computed get imgList() {

        let imgList = [];

        /*
        * 填充sku的图片列表
        * */
        if (this._currentSku?.skuPicList?.length) {
            imgList = [...this._currentSku.skuPicList?.split(";")];
        }

        /*
        * 填充商品的图片列表
        * */
        if (this._goods?.imgList?.length) {
            imgList = [...imgList, ...this._goods.imgList.split(";")];
        }
        if (this._goods?.mainPic) {
            imgList = [...imgList, this._goods.mainPic]
        }

        return imgList;
    }

    @action addNum() {
        this._num++
    }

    @action subNum() {
        this._num--
    }

    /**
     * 收藏点击事件
     */
    @action
    async switchCollect() {
        try {
            const {hasCollect, collectId, goods} = this
            if (hasCollect) {
                await Api.cancelCollect({collectId})
            } else {
                const {collectId: cid} = await Api.addCollect({
                    id: goods.id,
                    type: CollectEnum.GOODS
                })
                this._collectId = cid
            }
        } catch (error) {
        }
    }

    @action
    async fetchCartCount() {
        try {
            const {count} = await Api.cartGoodsCount()
            this._cartCount = count
        } catch (error) {
        }
    }

    get goodsId() {
        return this._goodsId;
    }

    set goodsId(value) {
        this._goodsId = value;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }

    get num() {
        return this._num;
    }

    set num(value) {
        this._num = value;
    }

    get skuList() {
        return this._skuList;
    }

    set skuList(value) {
        this._skuList = value;
    }

    get currentSku() {
        return this._currentSku;
    }

    set currentSku(value) {
        this._currentSku = value;
    }

    get goods() {
        return this._goods;
    }

    set goods(value) {
        this._goods = value;
    }

    get hasCollect() {
        return this._hasCollect;
    }

    set hasCollect(value) {
        this._hasCollect = value;
    }

    get collectId() {
        return this._collectId;
    }

    set collectId(value) {
        this._collectId = value;
    }

    get goodsAttributeList() {
        return this._goodsAttributeList;
    }

    set goodsAttributeList(value) {
        this._goodsAttributeList = value;
    }

    get specificationMapList() {
        return this._specificationMapList;
    }

    set specificationMapList(value) {
        this._specificationMapList = value;
    }

    get goodsPromotionUrl() {
        return this._goodsPromotionUrl;
    }

    set goodsPromotionUrl(value) {
        this._goodsPromotionUrl = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    get cartCount() {
        return this._cartCount;
    }

    set cartCount(value) {
        this._cartCount = value;
    }

    get goodsPropList() {
        return this._goodsPropList;
    }

    set goodsPropList(value) {
        this._goodsPropList = value;
    }
}

const store = new DetailStore()

export default store
