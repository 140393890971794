import { observable, action } from 'mobx';
import * as Api from 'utils/api/api';

class Help {
  @observable _menuList = [];

  get menuList() {
    return this._menuList;
  }
  set menuList(value) {
    this._menuList = value;
  }

  /**
   * 初始化
   */
  @action init() {
    this._menuList = [];
  }

  /**
   * 获取菜单列表
   */
  @action async fetchMenuList() {
    try {
      let { list } = await Api.helpMenuList();

      let menuList = [];
      list.forEach(item => {
        if (!item.article) {
          menuList.push(item);
        }
      });
      list.forEach(item => {
        if (item.article) {
          menuList.push(item);
        }
      });

      this._menuList = menuList.map(item => {
        let {
          id,
          title,
          name,
          article: isArticle,
          articles: articleList
        } = item;

        return {
          id,
          title: title || name,
          isArticle,
          articleList,
          active: false
        };
      });
    } catch (error) {}
  }

  /**
   * 选择菜单
   * @param {*} param0
   */
  @action async selectMenu(index) {
    this._menuList[index].active = !this._menuList[index].active;
    // if (this._menuList[index].active) {
    //   this._menuList[index].active = false;
    // } else {
    //   this._menuList.forEach((item, i) => {
    //     if (index !== i) {
    //       item.active = false;
    //     } else {
    //       item.active = true;
    //     }
    //   });
    // }
  }
}

const store = new Help();

export default store;
