import React, {useEffect} from 'react'
import './playing-panel.scss'
import GiftPanel from "./partials/gift-panel/GiftPanel";
import {observer} from "mobx-react";
import {useLocalStore} from "mobx-react-lite";
import GiftAnimate from "./partials/gift-animate/GiftAnimate";
import CurrentGoods from "./partials/current-goods/CurrentGoods";
import GoodsListPanel from "./partials/goods-list-panel/GoodsListPanel";
import UserListPanel from "./partials/user-list-panel/UserListPanel";

const PlayingPanel = (
    {
        chatMessageListRef,
        onSendBulletComments,
        onSendGift,
        bulletCommentList,
        bulletComment,
        onBulletCommentChange,
        giftMessageList,
        removeMessage,
        currentGoods,
        goodsList,
        heat,
        liveName,
        memberList,
        defaultAvatar,
        defaultNickname
    }
) => {

    const localStore = useLocalStore(() => ({
        showGiftPanel: false,
        showGoodsListPanel: false,
        showUserListPanel: false,
        giftMessage1: undefined,
        giftMessage1Count: 0,
        giftMessage2: undefined,
        giftMessage2Count: 0,
    }))


    /**
     * 控制礼物动画队列
     */
    useEffect(() => {
        let innerGiftMessageList = [...giftMessageList];
        const hasGift = () => {
            return innerGiftMessageList.length > 0;
        }

        if (!hasGift()) {
            return;
        }
        if (localStore.giftMessage1) {
            /*
            * 礼物1的位置有相同的礼物则入队1个
            * */
            const index = innerGiftMessageList.findIndex(
                item => item.content === localStore.giftMessage1.content && item.from === localStore.giftMessage1.from
            );
            if (index !== -1) {
                localStore.giftMessage1Count = localStore.giftMessage1Count + 1;
                removeMessage(innerGiftMessageList[index].id)
                innerGiftMessageList.splice(index, 1);
            }
        } else {
            /*
            * 给礼物1的位置设置新的礼物
            * 添加的礼物必须满足以下条件之一：
            * 1：该礼物和礼物2不是同一种
            * 2：该礼物和礼物2不是同一个人送的
            * */

            const index = innerGiftMessageList.findIndex(
                item => item.content !== localStore.giftMessage2?.content || item.from !== localStore.giftMessage2.from
            );
            if (index !== -1) {
                localStore.giftMessage1 = {...innerGiftMessageList[0]}
                localStore.giftMessage1Count = 1;
                removeMessage(innerGiftMessageList[0].id)
                innerGiftMessageList.splice(0, 1)
            }
        }


        if (!hasGift()) {
            return;
        }
        if (localStore.giftMessage2) {
            /*
            * 和礼物2的位置有相同的礼物，则入队1个
            * */
            const index = innerGiftMessageList
                .findIndex(item => item.content === localStore.giftMessage2.content && item.from === localStore.giftMessage2.from);
            if (index !== -1) {
                localStore.giftMessage2Count = localStore.giftMessage2Count + 1;
                removeMessage(innerGiftMessageList[index].id)
                innerGiftMessageList.splice(index, 1)
            }
        } else {
            /*
            * 给礼物2位置添加新的礼物
            * 新的礼物必须满足以下条件之一：
            * 1：该礼物和礼物1不是同一种
            * 2：该礼物和礼物1不是同一个人送的
            * */
            const index = innerGiftMessageList.findIndex(
                item => item.content !== localStore.giftMessage1?.content || item.from !== localStore.giftMessage1.from
            )

            if (index !== -1) {
                localStore.giftMessage2 = {...innerGiftMessageList[0]}
                localStore.giftMessage2Count = 1;
                removeMessage(innerGiftMessageList[0].id)
                innerGiftMessageList.splice(0, 1)
            }
        }
    }, [giftMessageList])

    const toBuy = (goodsId) => {
        window.open(`/mobile/detail/${goodsId}?type=0`)
    }

    return (
        <div className="glx-live-room__playing-panel">
            {/*顶层容器*/}
            <div className="top-container">
                <div className="actor-info-container">
                    <img src={`${process.env.PUBLIC_URL}/img/user/default-avatar.png`} alt="" className="avatar"/>
                    <div className="info">
                        <div className="nickname">{liveName}</div>
                        <div className="heat">{heat} 热度</div>
                    </div>
                </div>
                <div className="member-avatar-container" onClick={() => {
                    localStore.showUserListPanel = true;
                }}>
                    {
                        memberList.slice(0, 5).map((item, index) => (
                            <div className="item" key={item.userID} style={{
                                '--index': index,
                                backgroundImage: `url(${item.avatar || defaultAvatar})`
                            }}>
                            </div>
                        ))
                    }
                </div>
            </div>
            {/*礼物信息容器*/}
            <div className="gift-list-container">
                <div
                    className="item"
                >
                    <GiftAnimate
                        giftMessage={localStore.giftMessage1}
                        count={localStore.giftMessage1Count}
                        afterAnimate={() => {
                            localStore.giftMessage1 = undefined
                            localStore.giftMessage1Count = 0
                        }}
                        defaultAvatar={defaultAvatar}
                        defaultNickname={defaultNickname}
                    />
                </div>
                <div className="item">
                    <GiftAnimate
                        giftMessage={localStore.giftMessage2}
                        count={localStore.giftMessage2Count}
                        afterAnimate={() => {
                            localStore.giftMessage2 = undefined
                            localStore.giftMessage2Count = 0
                        }}
                    />

                </div>
            </div>
            {/*聊天信息容器*/}
            <div className="chat-message-list-container">
                <div className="chat-message-list" ref={chatMessageListRef}>
                    {
                        bulletCommentList.map(item => {
                            return (
                                <div className={`message-container ${item.isTip ? 'tip' : ''}`}
                                     key={item.id}>
                                    {
                                        !item.isTip && <div className="from">{item.fromProfile.nick}:</div>
                                    }

                                    <div className="content">{item.content}</div>
                                </div>
                            )
                        })
                    }
                </div>

                {/*当前商品*/}
                {
                    currentGoods &&
                    <div className="current-goods-container">
                        <CurrentGoods goods={currentGoods} toBuy={toBuy}/>
                    </div>
                }
            </div>
            {/*聊天控制容器*/}
            <div className="chat-control-container">
                <div className="input-wrapper">
                    <input
                        type="text"
                        value={bulletComment}
                        onChange={e => onBulletCommentChange(e.target.value)}
                        placeholder='打个招呼吧...'
                        className="input"
                        onKeyDown={e => onSendBulletComments(e.key)}
                    />
                </div>
                <div className="icon-container">
                    <div className="cart-item" onClick={() => localStore.showGoodsListPanel = true}>
                        <img src={require('./img/cart-icon.png')} alt=""
                             className="icon"/>
                    </div>
                    <div className="share-link-item">
                        <img src={require('./img/share-link-icon.png')} alt="" className="icon"/>
                    </div>
                    <div className="gift-item" onClick={() => localStore.showGiftPanel = true}>
                        <img src={require('./img/gift-icon.png')} alt="" className="icon"/>
                    </div>
                </div>
            </div>

            {/*底部阴影*/}
            <img src={require('./img/playing-panel-bottom-shadow.png')} alt=""
                 className="bottom-shadow"/>

            {/* 礼物面板 */}
            {
                localStore.showGiftPanel &&
                <div className="gift-panel-container">
                    <GiftPanel
                        onClose={() => {
                            localStore.showGiftPanel = false
                        }}
                        onSendGift={onSendGift}
                    />
                </div>
            }

            {/*商品列表*/}
            {
                localStore.showGoodsListPanel &&
                <div className="goods-list-panel-container" onClick={e => {
                    if (e.target.className === 'goods-list-panel-container') {
                        localStore.showGoodsListPanel = false
                    }
                }}>
                    <div className="goods-list-panel-wrapper">
                        <GoodsListPanel goodsList={goodsList} toBuy={toBuy}/>
                    </div>
                </div>
            }

            {
                localStore.showUserListPanel &&
                <div className="user-list-panel-container" onClick={e => {
                    if (e.target.className === 'user-list-panel-container') {
                        localStore.showUserListPanel = false
                    }
                }}>
                    <div className="user-list-panel-wrapper">
                        <UserListPanel userList={memberList} defaultAvatar={defaultAvatar} defaultNickname={defaultNickname}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default observer(PlayingPanel);