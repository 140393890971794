import React, {useEffect, useRef, useState} from 'react'
import './adv-floor1.scss';
import {inject, observer} from "mobx-react";
import Swiper from "swiper";

function AdvFloor1({home}) {
    const swiperRef = useRef(null)
    const [currentIndex, setCurrentIndex] = useState(0);
    const {advList1: advList} = home;

    useEffect(() => {
        if (!advList.length) {
            return;
        }

        new Swiper(swiperRef.current, {
            on: {
                slideChange() {
                    setCurrentIndex(this.activeIndex - 1);
                }
            }
        })
    }, [advList])

    function renderBanner() {
        const onClick = (link) => () => {
            window.open(link);
        }

        return advList.map(({id, imgUrl, link}, index) => {
            return (
                <div className='swiper-slide' key={id} onClick={onClick(link)}>
                    <div className='img-block'>
                        <img src={imgUrl} alt='' className='img'/>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="glx-home-adv-floor1">
            <div className="banner">
                <div className='swiper-container' ref={swiperRef}>
                    <div className='swiper-wrapper'>
                        {renderBanner()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default inject('home')(observer(AdvFloor1));