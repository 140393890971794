import {observable, computed, action} from 'mobx';
import * as Api from 'utils/api/api';

class Cart {
    @observable _info = null;
    @observable _list = [];
    @observable _goodsCount = 0;
    @observable _edit = false;

    @computed get allActive() {
        for (let i in this._list) {
            if (this._list[i].active === false) {
                return false;
            }
        }

        return true;
    }

    @computed get activeCount() {
        return this.activeList.length;
    }

    @computed get activeList() {
        return this._list.filter(item => item.active);
    }

    @computed get activeTotalPrice() {
        return this._list
            .filter(item => item.active)
            .reduce((sum, item) => {
                return sum + item.goodsTotalPrice;
            }, 0);
    }

    @action init() {
        this._info = null;
        this._list = [];
        this._goodsCount = 0;
        this._edit = false;
    }

    /**
     * 切换编辑状态
     */
    @action switchEdit() {
        this._edit = !this._edit;
    }

    /**
     * 获取购物车详情
     */
    @action
    async fetchInfo() {
        try {
            const {info, list} = await Api.cartInfo();

            this._info = info;
            this._list = await Promise.all(
                list.map(async item => {
                    const {goodsId} = item;
                    let {list: skuList} = await Api.skuListByGoodsId({goodsId});
                    skuList = skuList.map(sku => {
                        const specification = sku.specificationNameAndValues
                            .map(spec => spec.name)
                            .join(',');

                        return {
                            id: sku.id,
                            goodsId: sku.goodsId,
                            specification: specification
                        };
                    });

                    return {
                        ...item,
                        active: false,
                        skuList
                    };
                })
            );
        } catch (error) {
        }
    }

    /**
     * 选择商品
     * @param {*} index
     * @param {*} active
     */
    @action modifyActive(index, active) {
        this._list = this._list.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    active
                };
            } else {
                return item;
            }
        });
    }

    /**
     * （取消）全选
     * @param {*} active
     */
    @action modifyAllActive(active) {
        this._list = this._list.map(item => {
            return {
                ...item,
                active
            };
        });
    }

    /**
     * 修改商品数量
     * @param {*} cartId
     * @param {*} goodsId
     * @param {*} skuId
     * @param {*} number
     */
    @action
    async modifyGoodsCount(cartId, goodsId, skuId, number) {
        try {
            for (let key in this._list) {
                let goods = this._list[key];

                if (skuId === goods.productId) {
                    await Api.cartModifyGoodsNum({
                        goodsId,
                        skuId,
                        number,
                        cartId
                    });

                    this.fetchInfo();

                    return;
                }
            }
        } catch (error) {
        }
    }

    /**
     * 删除商品
     * @param {*} cartIdList
     */
    @action
    async deleteCart(cartIdList) {
        try {
            await Api.cartDelete({cartIdList});
            this.fetchInfo();
        } catch (error) {
        }
    }

    /**
     * 修改sku
     * @param {*} param0
     */
    @action
    async changeSku({cartId, goodsId, skuId, newSkuId, number}) {
        try {
            await Api.cartChangSku({cartId, goodsId, skuId, newSkuId, number});
            this.fetchInfo();
        } catch (error) {
        }
    }

    @action
    async fetchGoodsCount() {
        try {
            const {count} = await Api.cartGoodsCount();
            this.goodsCount = count;
        } catch (error) {
        }
    }

    get edit() {
        return this._edit;
    }

    set edit(value) {
        this._edit = value;
    }

    get info() {
        return this._info;
    }

    set info(info) {
        this._info = info;
    }

    get list() {
        return this._list;
    }

    set list(list) {
        this._list = list;
    }

    get goodsCount() {
        return this._goodsCount;
    }

    set goodsCount(value) {
        this._goodsCount = value;
    }
}

const store = new Cart();
export default store;
